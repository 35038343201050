export default class Position {
  page_pos: number = 0;
  page_size: number = 0;
  page_num: number = 0;
  total_results: number = 0;
  x: number = 0;
  y: number = 0;

  public constructor(init?:Partial<Position>) {
    Object.assign(this, init);
  }
}