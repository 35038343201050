import { EventType } from '@consts/Events';
import { validateRequestType } from '@validation/requestType';
import Component from '@entities/Component';
import MetaData from "@entities/MetaData";

export default class TopicEventRequest {
    event: any; 
    component?: Component; 
    metaData?: Array<MetaData>;
    readonly eventType: EventType.Topic;

    public constructor(init?:Partial<TopicEventRequest>) {
        validateRequestType(EventType.Topic, typeof init, typeof this);
        Object.assign(this, {...init, eventType: EventType.Topic});
    }
}