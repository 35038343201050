import Logger from "./abstract/Logger";
import { randomIdFromDate } from "@helpers/utils";
import ClientChannelMeta from "./ClientChannelMeta";

export class UserState {
    sessionId?: string = "";
    visitorId?: string = "";

    public constructor(init?:Partial<UserState>) {
      Object.assign(this, init);
    }
}

export default class State {
    client_key: string = "";
    client_reference: string = null;
    request_id: string = "";
    enableAutoClick: boolean = false;
    sendPageLoad: boolean = false;
    manualPageLoadTrackingStringsToMatch: string[] = null;
    isAuthenticated: boolean = false;
    user: UserState = new UserState();
    captureUrl: string = '';
    dataSource: string = '';
    clientChannelMeta: ClientChannelMeta = new ClientChannelMeta({});
    logger: Logger;
    consoleLogEvents: boolean = false;

    public constructor(init?:Partial<State>) {
      Object.assign(this, init);
    }

    setClientRef (client_ref: any) : void {
      if (client_ref) {
        this.isAuthenticated = true;
        this.client_reference = client_ref;
      } else {
        this.isAuthenticated = false;
        this.client_reference = null;
      }
    };

    setVisitorId() : void {
        this.user.visitorId = randomIdFromDate(true);
    };
    
    setSessionId() : void {
      this.user.sessionId = randomIdFromDate();    
    };

    setClientKey(clientKey: string) : void {
      this.client_key = clientKey;
    };
    
    setRequestId(reqId: string) : void {
      this.request_id = reqId;
    };

    setAutoClick(autoClick: boolean) : void {
      this.enableAutoClick = autoClick;
    };
    
    setAutoPageLoad(autoPageLoad: boolean) : void {
      this.sendPageLoad = autoPageLoad;
    };

    setUserState(userState: UserState) : void {
      this.user = userState;
    };

    setConsoleLogEvents(consoleLogEvents: boolean) : void {
      this.consoleLogEvents = consoleLogEvents;
    };

    setCaptureUrl(captureUrl: string) : void {
      this.captureUrl = captureUrl;
    };

    setClientChannelMeta(clientChannelMeta: ClientChannelMeta) {
      this.clientChannelMeta = clientChannelMeta;
    };

    setDataSource(dataSource: string) : void {
      this.dataSource = dataSource;
    };

    setLogger(logger: Logger) : void {
      this.logger = logger;
    };
}