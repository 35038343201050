import State from "@entities/State";

import Component from "@entities/Component";
import MetaData from "@entities/MetaData";
import ClientChannelMeta from "@entities/ClientChannelMeta";
export { Component, MetaData, ClientChannelMeta };

export * from "@consts/Actions";
export * from "@consts/Events";
export * from "@consts/MetaData";
export * from "@consts/Logging";
export * from "@events/index";

import AuthenticationEventRequest from "@requests/AuthenticationEventRequest";
import ClickEventRequest from "@requests/ClickEventRequest";
import ContentEventRequest from "@requests/ContentEventRequest";
import CustomEventRequest from "@requests/CustomEventRequest";
import EntityEventRequest from "@requests/EntityEventRequest";
import FilterEventRequest from "@requests/FilterEventRequest";
import LoadEventRequest from "@requests/LoadEventRequest";
import NotifictionEventRequest from "@requests/NotificationEventRequest";
import ReactionEventRequest from "@requests/ReactionEventRequest";
import RecomendationEventRequest from "@requests/ReactionEventRequest";
import ResearchEventRequest from "@requests/ResearchEventRequest";
import SearchEventRequest from "@requests/SearchEventRequest";
import TopicEventRequest from "@requests/TopicEventRequest";
import TradeEventRequest from "@requests/TradeEventRequest";
import TransactionEventRequest from "@requests/TransactionEventRequest";

import Logger from "@entities/abstract/Logger";

export {
    AuthenticationEventRequest,
    ClickEventRequest,
    ContentEventRequest,
    CustomEventRequest,
    EntityEventRequest,
    FilterEventRequest,
    LoadEventRequest,
    NotifictionEventRequest,
    ReactionEventRequest,
    RecomendationEventRequest,
    ResearchEventRequest,
    SearchEventRequest,
    TopicEventRequest,
    TradeEventRequest,
    TransactionEventRequest,
    Logger
};

export { updateFromConfig as create } from "@store/index";

declare global {
    var CaptureState: State;
}

globalThis.CaptureState = globalThis.CaptureState || new State();

const setClientRef = (clientRef: string) : void =>
    globalThis.CaptureState.setClientRef(clientRef)

const setRequestId = (requestId: string) : void =>
    globalThis.CaptureState.setRequestId(requestId)

export { setClientRef, setRequestId };