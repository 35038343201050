import Payload from "@entities/Payload";
import SendRequest from "@requests/SendRequest";
import { 
    getPayloadEventType, 
    getPayloadPosition, 
    getPayloadRelatedentities, 
    getPayloadChannelMeta, 
    getPayloadEventMetaData
} from "@helpers/payload";
import { getAppVersion } from "@services/app";

export function toPayload(
    request: SendRequest
) : Payload {
    const {
        url,
        metaData,
        component,
        event,
        eventType,
        eventAction,
        eventDetail
    } = request;

    return new Payload({
        app_version: getAppVersion() || 'unknown',
        client_key: globalThis.CaptureState.client_key,
        send_date_time: new Date(),
        data_source: globalThis.CaptureState.dataSource,
        page: url,
        event_type: getPayloadEventType(eventType, eventAction, eventDetail),
        action: eventType.toLowerCase(),
        component: component,
        position: getPayloadPosition(event, metaData),
        event_meta: getPayloadEventMetaData(metaData),
        related_entities: getPayloadRelatedentities(metaData),
        channel_meta: getPayloadChannelMeta(getPayloadEventType(eventType, eventAction, eventDetail))
    })
}