import { EventType, EventDetail } from "@consts/Events";
import { validateRequestType } from '@validation/requestType';
import Component from "@entities/Component";
import MetaData from "@entities/MetaData";

export default class CustomEventRequest {
    event?: any;
    eventType?: EventType;
    eventAction?: any;
    eventDetail?: EventDetail;
    component?: Component;
    metaData?: Array<MetaData>;

    public constructor(init?:Partial<CustomEventRequest>) {
        validateRequestType(this.eventType, typeof init, typeof this);
        Object.assign(this, init);
    }
}