import { TradeAction } from '@consts/Actions';
import { validateRequestType } from '@validation/requestType';
import { EventDetail, EventType } from '@consts/Events';
import Component from '@entities/Component';
import MetaData from "@entities/MetaData";

export default class TradeEventRequest {
    event: any; 
    eventAction: TradeAction;
    eventDetail?: EventDetail;
    component?: Component; 
    metaData?: Array<MetaData>;
    readonly eventType: EventType.Trade;

    public constructor(init?:Partial<TradeEventRequest>) {
        validateRequestType(EventType.Trade, typeof init, typeof this);
        Object.assign(this, {...init, eventType: EventType.Trade});
    }
}