export default class ChannelMeta {
  persistent_id: string = "<persistent_id>";
  session_id: string = "<session_id>";
  request_id: string = "<request_id>";
  client_ref: string = "<request_id>";
  timezone_offset: string = "<offset>";
  device_type: string = "<device category>";
  device_platform: string = "<device platform>";
  screen_width: number = 0;
  screen_height: number = 0;
  browser_width: number = 0;
  browser_height: number = 0;
  culture_code: string = "<culture code>";
  os: string = "<OS>";
  browser: string = "<Browser>";

  public constructor(init?:Partial<ChannelMeta>) {
    Object.assign(this, init);
  }
}