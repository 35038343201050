import { LogLevel } from "@consts/Logging";
import Logger from "@entities/abstract/Logger";

export default class ConsoleLogger extends Logger {
    executeLog(
        message: string,
        level: LogLevel,
        content?: any
    ) : void {
        if(level !== LogLevel.Error && !globalThis.CaptureState.consoleLogEvents)
            return;
    
        if(content)
            console[level](`WBC Event - ${message}: `, content);
        else
            console[level](`WBC Event - ${message}`);
    }
}