import { LogLevel } from "@consts/Logging";

export default abstract class Logger {
    constructor() { }

    log(
        message: string,
        content?: any,
    ) : void {
        this.executeLog(message, LogLevel.Debug, content);
    }

    info(
        message: string,
        content?: any,
    ) : void {
        this.executeLog(message, LogLevel.Information, content);
    }

    warn(
        message: string,
        content?: any,
    ) : void {
        this.executeLog(message, LogLevel.Warning, content);
    }

    error(
        message: string,
        content?: any
    ) : void {
        this.executeLog(message, LogLevel.Error, content);
    }

    abstract executeLog(
        message: string,
        level: LogLevel,
        content?: any
    ) : void;
}