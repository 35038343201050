import { LoadAction } from '@consts/Actions';
import { validateRequestType } from '@validation/requestType';
import { EventType } from '@consts/Events';
import MetaData from "@entities/MetaData";

export default class LoadEventRequest {
    url: string;
    eventAction?: LoadAction;
    metaData?: Array<MetaData>;
    readonly eventType: EventType.Load;

    public constructor(init?:Partial<LoadEventRequest>) {
        validateRequestType(EventType.Load, typeof init, typeof this);
        Object.assign(this, {...init, eventType: EventType.Load});
    }
}