export enum EventType {
    Load = "pageload",
    Click = "click",
    Recommendation = "recommendation",
    Notification = "notification",
    Entity = "entity",
    Topic = "topic",
    Content = "content",
    Search = "search",
    Filter = "filter",
    Research = "research",
    Authentication = "authentication",
    Transaction = "transaction",
    Trade = "trade",
    Reaction = "reaction",
}

export enum EventDetail {
    Add = "add",
    Remove = "remove",
    Create = "create",
    Delete = "delete",
    Modify = "modify",
    Ascending = "ascending",
    Descending = "descending",
    Close = "close",
    Open = "open"
}