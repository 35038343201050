import { EventType } from "@consts/Events";
import { Validation } from "@locale/lang";

export function validateRequestType(
    eventType: EventType,
    requestType: string,
    expectedRequestType: string
) : void {
    if(requestType !== expectedRequestType) {
        const msg = `${Validation.EventType}: ${eventType} ${Validation.RequiresType} ${expectedRequestType} ${Validation.RecievedType} ${requestType}`;
        globalThis.CaptureState.logger.error(msg, eventType);
        throw msg;
    }; 
}