export const Send = {
    "UrlError": "No tracking url set",
    "Request": "Tracking with payload",
    "Response": "Tracking response",
    "Error": "Error logging event"
};
export const Validation = {
    "EventType": "Event type of",
    "MetaDataType": "Incorrect Meta Data type recieved",
    "RequiresType": "requires a type of",
    "RecievedType": "but recieved a type of"
};