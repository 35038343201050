import { MetaDataKey } from "@consts/MetaData";

export default class MetaData {
  key: MetaDataKey | string;
  type?: string;
  value: string;

  public constructor(init?:Partial<MetaData>) {
    Object.assign(this, init);
  }
}