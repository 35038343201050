import { OsType, DeviceType } from "@consts/ChannelMeta";

export function getDeviceType() : string {
  const { device_type } = globalThis.CaptureState.clientChannelMeta;

  if(device_type)
    return device_type;

  if(!globalThis.navigator)
    return null;

  const { userAgent } = globalThis.navigator;

  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userAgent))
    return DeviceType.Tablet;
  
  if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(userAgent))
    return DeviceType.Mobile;

  return DeviceType.Desktop;
};

export function getDeviceOS() : string {
    const { os } = globalThis.CaptureState.clientChannelMeta;

  if(os)
    return os;

  if(!globalThis.navigator)
    return OsType.Unknown;

  const { userAgent } = globalThis.navigator;

  if (userAgent.indexOf("Win") != -1)
    return OsType.Windows;

  if (userAgent.indexOf("Mac") != -1)
    return OsType.Macintosh;

  if (userAgent.indexOf("Linux") != -1)
    return OsType.Linux;

  if (userAgent.indexOf("Android") != -1) 
    return OsType.Android;

  if (userAgent.indexOf("like Mac") != -1)
    return OsType.iOS;

  return OsType.Unknown;
};

export function getDeviceScreenWidth() : number {
  const { screen_width } = globalThis.CaptureState.clientChannelMeta;

  if(screen_width)
    return screen_width;

  return globalThis.screen?.width || null;
}

export function getDeviceScreenHeight() : number {
  const { screen_height } = globalThis.CaptureState.clientChannelMeta;

  if(screen_height)
    return screen_height;

  return globalThis.screen?.height || null;
}

export function getDevicePlatform() : string {
  const { device_platform } = globalThis.CaptureState.clientChannelMeta;

  if(device_platform)
    return device_platform;

  return globalThis.navigator?.platform || null;
}
