import { EventType, EventDetail } from "@consts/Events";
import { validateRequestType } from '@validation/requestType';
import Component from "@entities/Component";
import IRequestModel from "@entities/interfaces/IRequestModel";
import MetaData from "@entities/MetaData";

export default class SendRequest implements IRequestModel {
    event?: any;
    eventType?: EventType;
    eventAction?: any;
    eventDetail?: EventDetail;
    component?: Component;
    metaData?: Array<MetaData> = [];
    url?: string = globalThis.window.location.href;

    public constructor(init?:Partial<SendRequest>) {
        validateRequestType(this.eventType, typeof init, typeof this);
        Object.assign(this, {...init, metaData: init.metaData || []});
    }
}