import { AuthenticationAction } from '@consts/Actions';
import { validateRequestType } from '@validation/requestType';
import { EventType } from '@consts/Events';
import MetaData from "@entities/MetaData";

export default class AuthenticationEventRequest {
    event: any;
    eventAction?: AuthenticationAction;
    metaData?: Array<MetaData>;
    readonly eventType: EventType.Authentication

    public constructor(init?:Partial<AuthenticationEventRequest>) {
        validateRequestType(EventType.Authentication, typeof init, typeof this);
        Object.assign(this, {...init, eventType: EventType.Authentication});
    }
}