import Payload from "@entities/Payload";
import SendRequest from "@requests/SendRequest";
import { toPayload } from "@mappers/payloadMapper";
import { Send } from "@locale/lang";

function postEvent(
  payload: Payload
) : void {
  const url = globalThis.CaptureState.captureUrl;
  if(!url) {
    globalThis.CaptureState.logger.error(Send.UrlError);
    return;
  }

  globalThis.CaptureState.logger.log(Send.Request, payload);

  fetch(url, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: { "Content-Type": "application/json" },
  })
    .then((res: any) => globalThis.CaptureState.logger.info(Send.Response, res))
    .catch((error: any) => globalThis.CaptureState.logger.warn(Send.Response, error));
}

export default function (
  request: SendRequest
) : void {
  const payload = toPayload(request);
  postEvent(payload);
};

