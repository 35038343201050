import MetaData from "@entities/MetaData";
import Position from "@entities/Position";
import { getPagePosition, getBrowserVersion, getBrowserWidth, getBrowserHeight } from "@services/browser"
import { MetaDataKey } from "@consts/MetaData";
import RelatedEntity from "@entities/RelatedEntity";
import ChannelMeta from "@entities/ChannelMeta";
import { getDeviceOS, getDeviceType, getDeviceScreenWidth, getDeviceScreenHeight, getDevicePlatform } from "@services/device";
import { getRegionCultureCode, getRegionTimezone } from "@services/region";
import { randomIdFromDate } from "@helpers/utils";

export function getPayloadEventType (
    eventType?: string,
    eventAction?: string,
    eventDetail?: string,
  ) : string {
    eventType = eventType.toLowerCase();
    eventAction !== undefined ? eventAction.toLowerCase() : null;
    eventDetail !== undefined ? eventDetail.toLowerCase() : null;
    if (eventAction !== undefined && eventDetail !== undefined) {
      return `${eventType}_${eventAction}_${eventDetail}`;
    } else if (eventAction !== undefined) {
      return `${eventType}_${eventAction}`;
    } else {
      return eventType;
    }
}

export function getPayloadPosition(
    event?: any,
    metaData?: Array<MetaData>
) : Position {
    let position = new Position(); 

    if(event) {
        if (typeof event === typeof MouseEvent) {
            position.x = parseFloat((event.clientX / globalThis.window.outerWidth).toFixed(2));
            position.y = parseFloat((event.clientY / globalThis.window.outerHeight).toFixed(2));
        }
        position.page_pos = getPagePosition(event.target);
    };

    position.page_num = parseInt(
        metaData.find(d => d.key === MetaDataKey.PageNumber)?.value
    ) || null;

    position.page_size = parseInt(
        metaData.find(d => d.key === MetaDataKey.PageSize)?.value
    ) || null;

    position.total_results = parseInt(
        metaData.find(d => d.key === MetaDataKey.SearchResults)?.value
    ) || null;

    return position;
}

export function getPayloadChannelMeta(fullEventType: string) : ChannelMeta {
    return new ChannelMeta({
        os: getDeviceOS(),
        browser: getBrowserVersion(),
        device_platform: getDevicePlatform() || getBrowserVersion(),
        device_type: getDeviceType(),
        timezone_offset: getRegionTimezone(),
        session_id: globalThis.CaptureState.user.sessionId,
        request_id: getPayloadChannelRequestId(fullEventType),
        persistent_id: globalThis.CaptureState.client_reference,
        client_ref: globalThis.CaptureState.client_reference,
        screen_width: getDeviceScreenWidth(),
        screen_height: getDeviceScreenHeight(),
        browser_width: getBrowserWidth(),
        browser_height: getBrowserHeight(),
        culture_code: getRegionCultureCode()
    });
}

export function getPayloadRelatedentities(
    metaData?: Array<MetaData>
) : Array<RelatedEntity> {
    if(metaData.length < 1) return;

    return metaData
        .filter(d => d.key === MetaDataKey.RelatedEntity)
        .map(d => new RelatedEntity({
            client_ref: d.value,
            ref_type: d.type
        }));
} 

export function getPayloadEventMetaData(
    metaData: Array<MetaData>
) : Array<MetaData> {
    return metaData.filter(d => d.key !== MetaDataKey.RelatedEntity) || [];
}

export function getPayloadChannelRequestId(fullEventType: string): string {
    return "".concat(fullEventType, "_").concat(randomIdFromDate(true));
}
