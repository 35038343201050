import { ClickAction } from '@consts/Actions';
import { validateRequestType } from '@validation/requestType';
import { EventType } from '@consts/Events';
import Component from '@entities/Component';
import MetaData from "@entities/MetaData";

export default class ClickEventRequest {
    event: any; 
    eventAction: ClickAction | string;
    component?: Component; 
    metaData?: Array<MetaData>;
    readonly eventType: EventType.Click

    public constructor(init?:Partial<ClickEventRequest>) {
        validateRequestType(EventType.Click, typeof init, typeof this);
        Object.assign(this, {...init, eventType: EventType.Click});
    }
}