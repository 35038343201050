import { FilterAction } from '@consts/Actions';
import { validateRequestType } from '@validation/requestType';
import { EventType } from '@consts/Events';
import Component from '@entities/Component';
import MetaData from "@entities/MetaData";

export default class FilterEventRequest {
    event: any; 
    eventAction: FilterAction;
    component?: Component; 
    metaData?: Array<MetaData>;
    readonly eventType: EventType.Filter;

    public constructor(init?:Partial<FilterEventRequest>) {
        validateRequestType(EventType.Filter, typeof init, typeof this);
        Object.assign(this, {...init, eventType: EventType.Filter});
    }
}