import { SearchAction } from '@consts/Actions';
import { validateRequestType } from '@validation/requestType';
import { EventType } from '@consts/Events';
import Component from '@entities/Component';
import MetaData from "@entities/MetaData";

export default class SearchEventRequest {
    event: any; 
    eventAction: SearchAction;
    component?: Component; 
    metaData?: Array<MetaData>;
    readonly eventType: EventType.Search;

    public constructor(init?:Partial<SearchEventRequest>) {
        validateRequestType(EventType.Search, typeof init, typeof this);
        Object.assign(this, {...init, eventType: EventType.Search});
    }
}