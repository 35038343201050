import Position from '@entities/Position';
import ChannelMeta from '@entities/ChannelMeta';
import Component from '@entities/Component';
import MetaData from '@entities/MetaData';
import RelatedEntity from '@entities/RelatedEntity';

export default class Payload {
  app_version: string = "";
  client_key: string = "";
  data_source: string = "";
  send_date_time: Date = null;
  page: string = "";
  event_type: string = "";
  action: string = "";
  position = new Position();
  event_meta: Array<MetaData> = [];
  related_entities?: Array<RelatedEntity> = [];
  channel_meta = new ChannelMeta();
  component: Component = new Component();

  public constructor(init?:Partial<Payload>) {
    Object.assign(this, init);
  }
}