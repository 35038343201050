import LoadEventRequest from "@requests/LoadEventRequest";
import { load } from "@events/index";

export function getBrowserVersion () : string {
  const { browser } = globalThis.CaptureState.clientChannelMeta;

  if(browser)
    return browser;

  if(!globalThis.navigator)
    return null;

  const { userAgent } = globalThis.navigator;

  let matchTest = userAgent.match(
    /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
  ) || [];

  if(matchTest.length < 1)
    return "";

  let tem;

  if (/trident/i.test(matchTest[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
    return "IE " + (tem[1] || "");
  }

  if (matchTest[1] === "Chrome") {
    tem = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
    if (tem != null) 
      return tem.slice(1).join(" ").replace("OPR", "Opera");
  }

  const { appName, appVersion } = globalThis.navigator;
  matchTest = matchTest[2]
    ? [matchTest[1], matchTest[2]]
    : [appName, appVersion, "-?"];

  if ((tem = userAgent.match(/version\/(\d+)/i)) != null)
    matchTest.splice(1, 1, tem[1]);

  return matchTest.join(" ");
};

export function getPagePosition (elem: Element) : number {
  let pagePos = 0;
  let buttons = [].slice.call(globalThis.document.getElementsByTagName("button"));
  pagePos = buttons.indexOf(elem);
  pagePos !== -1 ? (pagePos += 1) : pagePos;
  return pagePos;
};

export function  detectPageLoad () : void {
  let lastURL = globalThis.window.location.href;

  new MutationObserver(() => {
    const url = globalThis.window.location.href;
    if (url !== lastURL) {
      lastURL = url;

      if (globalThis.CaptureState.manualPageLoadTrackingStringsToMatch) {
        globalThis.CaptureState.manualPageLoadTrackingStringsToMatch.forEach(function(string){
          if (lastURL.indexOf(string) > -1) {
            return;
          }
        })
      }

      load(new LoadEventRequest({ url: lastURL }));
    }
  }).observe(globalThis.document, { subtree: true, childList: true });
};

export function getBrowserWidth() : number {
  return globalThis.outerWidth || null;
}

export function getBrowserHeight() : number {
  return globalThis.outerHeight || null;
}
