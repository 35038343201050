import { EventType } from '@consts/Events';
import { validateRequestType } from '@validation/requestType';
import Component from '@entities/Component';
import MetaData from "@entities/MetaData";

export default class NotifictionEventRequest {
    event: any; 
    component?: Component; 
    metaData?: Array<MetaData>;
    readonly eventType: EventType.Notification;

    public constructor(init?:Partial<NotifictionEventRequest>) {
        validateRequestType(EventType.Notification, typeof init, typeof this);
        Object.assign(this, {...init, eventType: EventType.Notification});
    }
}