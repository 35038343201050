export enum MetaDataKey {
  EntityId = 'entity_id',
  ContentId = 'content_id',
  ContentUrl = 'content_url',
  ContentTitle = 'content_title',
  Topic = 'topic',
  SearchTerm = 'term',
  UserEmail = 'user_email',
  UserId = 'user_id',
  PageNumber = 'page_number',
  PageSize = 'page_size',
  SearchResults = 'results',
  Tab = 'active_tab',
  RelatedEntity = 'related_entity',
  ContentType = 'content_type',
  SortField = 'sort_field'
}

export enum EntityMetaDataType {
  ASSymbol = "ASSymbol",
  ASName = "ASName",
  MarketSymbol = "MarketSymbol",
  MarketName = "MarketName",
  ClientSymbol = "ClientSymbol",
  ClientName = "ClientName"
}