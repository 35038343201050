import { ContentAction } from '@consts/Actions';
import { validateRequestType } from '@validation/requestType';
import { EventType } from '@consts/Events';
import Component from '@entities/Component';
import MetaData from "@entities/MetaData";

export default class EntityEventRequest {
    event: any; 
    eventAction: ContentAction | string;
    component?: Component; 
    metaData?: Array<MetaData>;
    readonly eventType: EventType.Entity

    public constructor(init?:Partial<EntityEventRequest>) {
        validateRequestType(EventType.Entity, typeof init, typeof this);
        Object.assign(this, {...init, eventType: EventType.Entity});
    }
}