import { ResearchAction } from '@consts/Actions';
import { validateRequestType } from '@validation/requestType';
import { EventDetail, EventType } from '@consts/Events';
import Component from '@entities/Component';
import MetaData from "@entities/MetaData";

export default class ResearchEventRequest {
    event: any;
    eventAction: ResearchAction 
    eventDetail: EventDetail
    component?: Component; 
    metaData?: Array<MetaData>;
    readonly eventType: EventType.Research;

    public constructor(init?:Partial<ResearchEventRequest>) {
        validateRequestType(EventType.Research, typeof init, typeof this);
        Object.assign(this, {...init, eventType: EventType.Research});
    }
}