import { AuthenticationAction, ContentAction, ReactionAction } from "@consts/Actions";
import AuthenticationEventRequest from "@requests/AuthenticationEventRequest";
import ClickEventRequest from "@requests/ClickEventRequest";
import ContentEventRequest from "@requests/ContentEventRequest";
import CustomEventRequest from "@requests/CustomEventRequest";
import EntityEventRequest from "@requests/EntityEventRequest";
import FilterEventRequest from "@requests/FilterEventRequest";
import LoadEventRequest from "@requests/LoadEventRequest";
import NotifictionEventRequest from "@requests/NotificationEventRequest";
import ReactionEventRequest from "@requests/ReactionEventRequest";
import RecomendationEventRequest from "@requests/ReactionEventRequest";
import ResearchEventRequest from "@requests/ResearchEventRequest";
import SearchEventRequest from "@requests/SearchEventRequest";
import SendRequest from "@requests/SendRequest";
import TopicEventRequest from "@requests/TopicEventRequest";
import TradeEventRequest from "@requests/TradeEventRequest";
import TransactionEventRequest from "@requests/TransactionEventRequest";
import send from "@services/send";
import { EventType } from "@consts/Events";

/**
* Authentication event function
* @param request - mandatory
*/
export function authentication(
  request: AuthenticationEventRequest, 
) : void {
  send(new SendRequest(request));
};

/**
* Basket event function
* @param request - mandatory
*/
export function basket(
  request: ReactionEventRequest, 
) : void {
  send(
    new SendRequest({ ...request, 
      eventAction: ReactionAction.Basket, 
    })
  );
};

/**
* Click event function
* @param request - mandatory
*/
export function click(
  request: ClickEventRequest, 
) : void {
  send(new SendRequest(request));
};

/**
* Comment event function
* @param request - mandatory
*/
export function comment(
  request: ReactionEventRequest, 
) : void {
  send( new SendRequest({ ...request,
      eventAction: ReactionAction.Comment, 
    })
  );
};

/**
* Content event function
* @param request - mandatory
*/
export function content(
  request: ContentEventRequest, 
) : void {
  send(new SendRequest(request));
};

/**
* Custom event function
* @param request - mandatory
*/
export function custom(
  request: CustomEventRequest, 
) : void {
  send(new SendRequest(request));
};

/**
* Entity event function
* @param request - mandatory
*/
export function entity(
  request: EntityEventRequest, 
) : void {
  send(new SendRequest(request));
};

/**
* Favourite event function
* @param request - mandatory
*/
export function favourite(
  request: ReactionEventRequest, 
) : void {
  send(
    new SendRequest({ ...request,
      eventAction: ReactionAction.Favourite, 
    })
  );
};

/**
* Filter event function
* @param request - mandatory
*/
export function filter(
  request: FilterEventRequest, 
) : void {
  send(new SendRequest(request));
};

/**
* Like event function
* @param request - mandatory
*/
export function like(
  request: ReactionEventRequest, 
) : void {
  send(
    new SendRequest({...request,
      eventAction: ReactionAction.Like, 
    })
  );
};

/**
* Load event function
* @param request - mandatory
*/
export function load(
  request: LoadEventRequest, 
) : void {
  send(new SendRequest(request));
};

/**
* Notification event function
* @param request - mandatory
*/
export function notification(
  request: NotifictionEventRequest, 
) : void {
  send(
    new SendRequest({...request,
      eventAction: ContentAction.FollowUrl, 
    })
  );
};

/**
* Recommendation event function
* @param request - mandatory
*/
export function recommendation(
  request: RecomendationEventRequest, 
) : void {
  send(
    new SendRequest({...request,
      eventAction: ContentAction.FollowUrl, 
    })
  );
};

/**
* Research event function
* @param request - mandatory
*/
export function research(
  request: ResearchEventRequest, 
) : void {
  send(new SendRequest(request));
};

/**
* Search event function
* @param request - mandatory
*/
export function search(
  request: SearchEventRequest, 
) : void {
  send(new SendRequest(request));
};

/**
* Topic event function
* @param request - mandatory
*/
export function topic(
  request: TopicEventRequest, 
) : void {
  send(
    new SendRequest({ ...request,
      eventAction: ContentAction.FollowUrl, 
    })
  );
};

/**
* Trade event function
* @param request - mandatory
*/
export function trade(
  request: TradeEventRequest, 
) : void {
  send(new SendRequest(request));
};

/**
* Transaction event function
* @param request - mandatory
*/
export function transaction(
  request: TransactionEventRequest, 
) : void {
  send(new SendRequest(request));
};

/**
* Watchlist event function
* @param request - mandatory
*/
export function watchlist(
  request: ReactionEventRequest, 
) : void {
  send(
    new SendRequest({ ...request,
      eventAction: ReactionAction.Watchlist, 
    })
  );
};

export function fireEvent (
  request: any
) : void {
  if (globalThis.CaptureState.enableAutoClick) {
    switch (request.eventType) {
      case EventType.Authentication:
        if(request.eventAction === AuthenticationAction.SignOut)
        globalThis.CaptureState.setClientRef(null);
        
        authentication(request);
        break;
      case EventType.Load:
        load(request);
        break;
      case EventType.Click:
        click(request);
        break;
      case EventType.Recommendation:
        recommendation(request);
        break;
      case EventType.Notification:
        notification(request);
        break;
      case EventType.Entity:
        entity(request);
        break;
      case EventType.Topic:
        topic(request);
        break;
      case EventType.Search:
        search(request);
        break;
      case EventType.Filter:
        filter(request);
        break;
      case EventType.Reaction:
        switch(request.eventAction) {
          case ReactionAction.Basket: basket(request); break;
          case ReactionAction.Comment: comment(request); break;
          case ReactionAction.Favourite: favourite(request); break;
          case ReactionAction.Like: like(request); break;
          case ReactionAction.Watchlist: watchlist(request); break;
        }
        break;
      case EventType.Research:
        research(request);
        break;
      case EventType.Transaction:
        transaction(request);
        break;
      case EventType.Trade:
        trade(request);
        break;
    }
  }
};
