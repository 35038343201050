import State from "@entities/State"
import ConsoleLogger from "@services/logging";

function initialiseStore() : State {
  return new State();
}

export function updateFromConfig (options: any) : void {
  initialiseStore();

  globalThis.CaptureState.setClientKey(options.apiKey);
  globalThis.CaptureState.setSessionId();
  globalThis.CaptureState.setAutoClick(options.setAutoClick || false);
  globalThis.CaptureState.setAutoPageLoad(options.setAutoPageLoad || false);
  globalThis.CaptureState.setConsoleLogEvents(options.logEvents || false);
  globalThis.CaptureState.setCaptureUrl(options.captureUrl || "https://behaviour-capture-receiver-serverless.azurewebsites.net/api/wbc_listener?code=G892JaeoDZD-Yif114Xn-EblkciSKfA3dBWDRFHdDDe7AzFu2d7M0Q==");

  globalThis.CaptureState.setDataSource(options.dataSource || globalThis.window.location.hostname.replace("www", ""));

  globalThis.CaptureState.setClientChannelMeta(options.clientChannelMeta || {});

  globalThis.CaptureState.setLogger(options.logger || new ConsoleLogger());

  if(options.isVisitor)
  globalThis.CaptureState.setVisitorId();
};