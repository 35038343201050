export enum FilterAction {
  Applied = "applied",
}

export enum ContentAction {
  Expand = "expand",
  Shrink = "shrink",
  FollowUrl = "follow_url",
  EnteredView = "entered_view",
  SeeMore = "see_more",
  SeeLess = "see_less",
  Select = "select"
}

export enum SearchAction {
  Applied = "applied",
  Result = "result",
  Filter = "filter",
  Selected = "selected",
  Sort = "sort",
  FocusOn = "focus_on",
  FocusAway = "focus_away",
  Close = "close"
}

export enum ResearchAction {
  Indicator = "indicator",
  AdjustView = "adjust_view",
  Filter = "filter",
  SortView = "sort_view",
  DateSelect = "date_select",
}

export enum AuthenticationAction {
  SignIn = "sign_in",
  SignOut = "sign_out",
  SignUp = "sign_up",
}

export enum TransactionAction {
  Withdraw = "withdraw",
  Deposit = "deposit",
  Buy = "buy",
  Sell = "sell",
}

export enum TradeAction {
  BuyMarket = "buy_market",
  BuyLimit = "buy_limit",
  BuyStop = "buy_stop",
  SellMarket = "sell_market",
  SellLimit = "sell_limit",
  SellStop = "sell_stop",
}

export enum LoadAction {
  Load = "load",
  Paged = "paged",
  TabSelected = "tab_selected",
}

export enum ClickAction {
  Entity = "entity",
}

export enum ReactionAction {
  Like = "like",
  Favourite = "favourite",
  Comment = "comment",
  Basket = "basket",
  Watchlist = "watchlist",
}