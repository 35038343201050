export enum OsType {
    Windows = "Windows",
    Macintosh = "Macintosh",
    Linux = "Linux",
    Android = "Android",
    iOS = "iOS",
    Unknown = "unknown"
}

export enum DeviceType {
    Desktop = "Desktop",
    Tablet = "Tablet",
    Mobile = "Mobile"
}