export function getRegionTimezone() : string {
    return globalThis.CaptureState.clientChannelMeta.timezone_offset || 
        Intl.DateTimeFormat().resolvedOptions().timeZone + " UTC" + new Date().getTimezoneOffset() / 60
}

export function getRegionCultureCode() : string {
    const { culture_code } = globalThis.CaptureState.clientChannelMeta;

    if(culture_code)
      return culture_code;

    return globalThis.navigator?.language || null
}