export default class ClientChannelMeta {
  timezone_offset: string;
  device_type: string;
  device_platform: string;
  screen_width: number;
  screen_height: number;
  culture_code: string;
  os: string;
  browser: string;

  public constructor(init?:Partial<ClientChannelMeta>) {
    Object.assign(this, init);
  }
}